import jwt from 'jsonwebtoken';
import * as UTILS from './utilFunctions';

export default function parseTokenToState(token) {
  const decodedToken = jwt.decode(token);
  const userRole = UTILS.getHighestUserRole(decodedToken.roles);
  const additionalPermissions = decodedToken.additionalPermissions;
  return {
    loggedIn: true,
    user: {
      role: userRole,
      id: decodedToken.id,
      additionalPermissions
    }
  };
}
