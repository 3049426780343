import React, { PureComponent } from 'react';
import './SortLabel.css';
import PropTypes from 'prop-types';

class SortLabel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    this.props.onClick(this.props.label);
  }

  /**
   * It returns a Meterial IO icon depending on the props isActive and direction
   */
  checkIfActive() {
    let element = 'more_horiz';
    if (this.props.isActive && this.props.direction) {
      element = 'arrow_downward';
    } else if (this.props.isActive && !this.props.direction) {
      element = 'arrow_upward';
    }
    return <i className="material-icons">{element}</i>;
  }

  render() {
    return (
      <button
        className={`SortLabel d-inline-flex ${this.props.isActive && 'active'}`}
        type="button"
        onClick={this.handleButtonClick}
      >
        {this.props.label}
        {this.checkIfActive()}
      </button>
    );
  }
}

SortLabel.propTypes = {
  direction: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default SortLabel;
