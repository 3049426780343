import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './StarRating.css';

class StarRating extends PureComponent {
  constructor(props) {
    super(props);

    this.onStarChange = this.onStarChange.bind(this);
  }

  onStarChange(e) {
    this.props.onStarChange(
      parseInt(e.currentTarget.value, 10),
      this.props.groupName
    );
  }

  render() {
    return (
      <div className="stars">
        <input
          type="radio"
          id={`${this.props.groupName}singleStar5`}
          name={this.props.groupName}
          value="5"
          onChange={this.onStarChange}
          checked={this.props.starValue === 5}
        />
        <label htmlFor={`${this.props.groupName}singleStar5`} />

        <input
          type="radio"
          id={`${this.props.groupName}singleStar4`}
          name={this.props.groupName}
          value="4"
          onChange={this.onStarChange}
          checked={this.props.starValue === 4}
        />
        <label htmlFor={`${this.props.groupName}singleStar4`} />

        <input
          type="radio"
          id={`${this.props.groupName}singleStar3`}
          name={this.props.groupName}
          value="3"
          onChange={this.onStarChange}
          checked={this.props.starValue === 3}
        />
        <label htmlFor={`${this.props.groupName}singleStar3`} />

        <input
          type="radio"
          id={`${this.props.groupName}singleStar2`}
          name={this.props.groupName}
          value="2"
          onChange={this.onStarChange}
          checked={this.props.starValue === 2}
        />
        <label htmlFor={`${this.props.groupName}singleStar2`} />

        <input
          type="radio"
          id={`${this.props.groupName}singleStar1`}
          name={this.props.groupName}
          value="1"
          onChange={this.onStarChange}
          checked={this.props.starValue === 1}
        />
        <label htmlFor={`${this.props.groupName}singleStar1`} />
      </div>
    );
  }
}
StarRating.defaultProps = {
  starValue: 3
};
StarRating.propTypes = {
  starValue: PropTypes.number,
  groupName: PropTypes.string.isRequired,
  onStarChange: PropTypes.func.isRequired
};

export default StarRating;
