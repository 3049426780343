import React from 'react';
import PropTypes from 'prop-types';
import ErrorStackParser from 'error-stack-parser';
import StackTraceGPS from 'stacktrace-gps';
import ErrorPage from '../pages/ErrorPage';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    if (process.env.NODE_ENV === 'production') {
      // log source-mapped stack trace to console
      const gps = new StackTraceGPS();
      const mapped = Promise.all(
        ErrorStackParser.parse(error).map(stackframe =>
          gps.pinpoint(stackframe)
        )
      );
      // this tricks Terser so the console.error is not removed in production
      const c = console;
      mapped.then(stack =>
        c.error(
          `Error caught in ErrorBoundary:\n  ${stack
            .map(f => f.toString())
            .join('\n  ')}`
        )
      );
    }
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage onRetry={() => this.setState({ hasError: false })} />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};
