import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import text from '../../../text';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

class IndustrySectorFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(arrayOfSelected) {
    this.props.onFilterChange(
      'industrySectorName',
      arrayOfSelected,
      FILTER_HELPER.filterFunctions.industrySectorName
    );
  }

  handleChange = name => event => {
    const arrayOfSelected = this.props.industrySectorName;
    if (event.target.checked) {
      arrayOfSelected.push(name);
    } else {
      const index = arrayOfSelected.indexOf(name);
      arrayOfSelected.splice(index, 1);
    }

    this.onChange(arrayOfSelected);
  };

  render() {
    const headline = `${text.filter.jobFilter.industry.headline}`;
    const countedJobParameters = this.props.countedJobParameters;

    const checkboxValues = this.props.stickyFilter
      ? this.props.industrySectorName
      : countedJobParameters.branches;
    return (
      <div>
        {checkboxValues &&
          checkboxValues.length > 0 && (
            <div className="col-12 pb-5 checkFilterBox">
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{headline}</FormLabel>
                <FormGroup>
                  {checkboxValues &&
                    checkboxValues.map(rawElement => {
                      let element = rawElement;
                      let elementCount;
                      let elementCountDOM = '';
                      if (rawElement.key) {
                        element = rawElement.key;
                        elementCount = rawElement.count;
                        elementCountDOM = `(${elementCount})`;
                      }
                      return (
                        <FormControlLabel
                          className="checkFilterLabel"
                          key={element}
                          control={
                            <Checkbox
                              className="chkBox_"
                              checked={this.props.industrySectorName.includes(
                                element
                              )}
                              onChange={this.handleChange(element)}
                              value={element}
                            />
                          }
                          label={`${element} ${elementCountDOM}`}
                        />
                      );
                    })}
                </FormGroup>
              </FormControl>
            </div>
          )}
      </div>
    );
  }
}

IndustrySectorFilter.defaultProps = {
  countedJobParameters: {},
  stickyFilter: false
};
IndustrySectorFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  // TODO renaming may be required
  // object is allowed in this case, as the object can have any shape
  countedJobParameters: PropTypes.object, // eslint-disable-line
  industrySectorName: PropTypes.arrayOf(PropTypes.string).isRequired,
  stickyFilter: PropTypes.bool
};

export default IndustrySectorFilter;
