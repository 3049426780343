import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';

import text from '../../../text';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

class WillignessToChangeFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { range: this.props.range };
    this.onChange = this.onChange.bind(this);
    this.onAfterChange = this.onAfterChange.bind(this);
  }

  /**
   * REACT lifecycle method to update the state while receiving new props
   * @param  {object} nextProps new props
   */
  componentWillReceiveProps(nextProps) {
    this.setState({ range: nextProps.range });
  }

  /**
   * OnChange method for the WillignessToChangeFilter component to update the component state while moving the handlers of the range slider
   * @param  {array} range array of values of the two handlers of slider
   */
  onChange(range) {
    this.setState({ range });
  }

  /**
   * OnAfterChange method for the WillignessToChangeFilter component, calls the onFilterChange function
   * @param  {array} range array of values of the two handlers of slider
   */
  onAfterChange(range) {
    this.props.onFilterChange(
      'willingnessToChange',
      range,
      FILTER_HELPER.filterFunctions.willingnessToChange
    );
  }

  render() {
    const headline = `${text.filter.mainFilter.willingnessToChange} (${
      this.state.range[0]
    }% - ${this.state.range[1]}%):`;
    return (
      <div className="col-12 pb-5">
        <p>{headline}</p>
        <Range
          value={this.state.range}
          step={5}
          min={0}
          max={100}
          marks={{
            0: '0%',
            20: '20%',
            40: '40%',
            60: '60%',
            80: '80%',
            100: '100%'
          }}
          onChange={this.onChange}
          onAfterChange={this.onAfterChange}
        />
      </div>
    );
  }
}
WillignessToChangeFilter.defaultProps = {};
WillignessToChangeFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired
};
export default WillignessToChangeFilter;
