import React from 'react';

import UserContext from './UserContext';

// This function takes a component...
export default function withUser(Component) {
  // ...and returns another component...
  return function UserComponent(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <UserContext.Consumer>
        {user => <Component {...props} user={user} />}
      </UserContext.Consumer>
    );
  };
}
